import { call, put, select, takeLatest } from 'typed-redux-saga';

import { SafeStorage } from '@/core/data/utils/SafeStorage/SafeStorage';
import { isMicroServiceError } from '@/domains/core/httpClient/utils/isMicroServiceError';
import { selectVisitorId } from '@/domains/core/tracking/selectors/selectVisitorId';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import { logShoppingCartError } from '@/domains/shoppingCart/common/utils/logShoppingCartError';
import {
  GET_CART_OVERVIEW_FAILED,
  GET_CART_OVERVIEW_MANAGER,
  GET_CART_OVERVIEW_SUCCESS,
} from '@/shoppingCart/CartOverview/actions/GetCartOverview.actions';
import {
  TTL_FIVE_MINUTES,
  TTL_SEVEN_DAYS,
} from '@/shoppingCart/CartOverview/CartOverview.constants';
import { type CartOverview } from '@/shoppingCart/CartOverview/interfaces/CartOverview';
import { fetchCartFromLocalStorage } from '@/shoppingCart/CartOverview/localStorage/fetchCartFromLocalStorage';
import { writeCartOverviewToLocalStorage } from '@/shoppingCart/CartOverview/localStorage/writeCartOverviewToLocalStorage';
import { formatLsForCartOverview } from '@/shoppingCart/CartOverview/utils/formatLsForCartOverview';
import { getCartOverview } from '@/shoppingCart/services/msfCart/getCartOverview';

export function* getCartOverviewManagerSaga() {
  try {
    let cartOverview: CartOverview;

    const { isAuthenticated, userId } = yield* select(authSelector);
    const visitorId = yield* select(selectVisitorId);
    const owner = `${isAuthenticated ? userId : visitorId}`;
    const ttl = isAuthenticated ? TTL_FIVE_MINUTES : TTL_SEVEN_DAYS;
    const fetchedFromLocalStorage = fetchCartFromLocalStorage({
      isAuthenticated,
      owner,
      ttl,
    });
    if (
      (fetchedFromLocalStorage || !isAuthenticated) &&
      SafeStorage.getLocalStorage().isStorageAvailable
    ) {
      cartOverview = formatLsForCartOverview(fetchedFromLocalStorage);
    } else {
      cartOverview = yield* call(getCartOverview);

      writeCartOverviewToLocalStorage({
        cartOverviewContents: cartOverview.contents,
        id: cartOverview.id,
        owner,
        ttl,
        productsTotalPrice: cartOverview.productsTotalPrice || 0,
      });
    }

    yield* put({ type: GET_CART_OVERVIEW_SUCCESS, cartOverview });
  } catch (error) {
    const { isAuthenticated, userId } = yield* select(authSelector);
    const visitorId = yield* select(selectVisitorId);
    const owner = `${isAuthenticated ? userId : visitorId}`;
    const ttl = isAuthenticated ? TTL_FIVE_MINUTES : TTL_SEVEN_DAYS;

    if (
      error?.response?.status === 404 &&
      error?.response?.data?.error?.app_code === 'CART_NOT_FOUND'
    ) {
      writeCartOverviewToLocalStorage({
        cartOverviewContents: {},
        id: 0,
        owner,
        ttl,
        productsTotalPrice: 0,
      });
    } else {
      if (!isMicroServiceError(error)) {
        logShoppingCartError(error, 'cartOverview', 'cartOverviewManager');
      }
      yield* put({ type: GET_CART_OVERVIEW_FAILED, error: error?.message });
    }
  }
}

export function* watchGetCartOverviewManager() {
  yield* takeLatest(GET_CART_OVERVIEW_MANAGER, getCartOverviewManagerSaga);
}
