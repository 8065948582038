import type { ProductDetails } from '@/domains/shoppingCart/interfaces/ProductDetails';
import type { MainCartLightContent } from '@/domains/shoppingCart/services/msfCart/interfaces/main';

export const MAIN_CART_ITEM_DELETION_REQUEST =
  'shoppingCart/MAIN_CART_ITEM_DELETION_REQUEST';
export const MAIN_CART_ITEM_DELETION_SUCCESS =
  'shoppingCart/MAIN_CART_ITEM_DELETION_SUCCESS';
export const MAIN_CART_ITEM_DELETION_FAILED =
  'shoppingCart/MAIN_CART_ITEM_DELETION_FAILED';

export interface MainCartItemDeletionPayload {
  productId: number;
  displayAlertMessage?: boolean;
  removedProduct: ProductDetails;
  position: number;
}

interface MainCartItemDeletionAction {
  payload: MainCartItemDeletionPayload;
}

export interface MainCartItemDeletionSuccess {
  mainCartLight: MainCartLightContent;
  productId: number;
  displayAlertMessage?: boolean;
  removedProduct: ProductDetails;
}

export type MainCartItemDeletionRequestAction = {
  type: typeof MAIN_CART_ITEM_DELETION_REQUEST;
} & MainCartItemDeletionAction;

export interface MainCartItemDeletionSuccessAction {
  type: typeof MAIN_CART_ITEM_DELETION_SUCCESS;
  payload: MainCartItemDeletionSuccess;
}

export type MainCartItemDeletionFailedAction = {
  type: typeof MAIN_CART_ITEM_DELETION_FAILED;
} & MainCartItemDeletionAction;

export type MainCartItemDeletionActions =
  | MainCartItemDeletionRequestAction
  | MainCartItemDeletionSuccessAction
  | MainCartItemDeletionFailedAction;

export const mainCartItemDeletion = ({
  productId,
  removedProduct,
  position,
  displayAlertMessage = true,
}: MainCartItemDeletionPayload): MainCartItemDeletionRequestAction => ({
  type: MAIN_CART_ITEM_DELETION_REQUEST,
  payload: { productId, displayAlertMessage, removedProduct, position },
});

export const mainCartItemDeletionFailed = (
  payload: MainCartItemDeletionPayload,
): MainCartItemDeletionFailedAction => ({
  type: MAIN_CART_ITEM_DELETION_FAILED,
  payload,
});

export const mainCartItemDeletionSuccess = (
  mainCartLight: MainCartLightContent,
  productId: number,
  displayAlertMessage = true,
  removedProduct: ProductDetails,
): MainCartItemDeletionSuccessAction => ({
  type: MAIN_CART_ITEM_DELETION_SUCCESS,
  payload: { mainCartLight, productId, displayAlertMessage, removedProduct },
});
