import { SafeStorage } from '@/domains/core/data/utils/SafeStorage/SafeStorage';

interface LocalStorage {
  key: string;
  options?: { [key: string]: any };
}
export const readLocalStorage = ({ key, options }: LocalStorage) => {
  const safeLocalStorage = SafeStorage.getLocalStorage();
  return safeLocalStorage.getItem(key, options);
};
