import { logShoppingCartError } from '@/domains/shoppingCart/common/utils/logShoppingCartError';

import { LOCAL_STORAGE_KEY } from '../CartOverview.constants';
import type {
  FetchCartFromLocalStorage,
  Options,
} from '../interfaces/LocalStorage';
import { readLocalStorage } from './readLocalStorage';

export const fetchCartFromLocalStorage = ({
  isAuthenticated,
  owner,
  ttl,
}: FetchCartFromLocalStorage) => {
  if (!owner) {
    logShoppingCartError(
      new Error(`${isAuthenticated ? 'userId' : 'visitorId'} undefined`),
      'cartOverview',
    );
    return null;
  }
  const options: Options = { owner, ttl };

  return readLocalStorage({ key: LOCAL_STORAGE_KEY, options });
};
