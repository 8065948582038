import type { countryCode as CountryCode } from '@/domains/customerManagement/interfaces/countryCode';
import type { CartError } from '@/domains/shoppingCart/interfaces/CartsState';
import type { MainCartRetrievalResponse } from '@/domains/shoppingCart/services/msfCart/mainCartRetrieval/interfaces';

export const MAIN_CART_RETRIEVAL_REQUEST =
  'shoppingCart/MAIN_CART_RETRIEVAL_REQUEST';
export const MAIN_CART_RETRIEVAL_FAILED =
  'shoppingCart/MAIN_CART_RETRIEVAL_FAILED';

export interface MainCartRetrievalRequestAction {
  type: typeof MAIN_CART_RETRIEVAL_REQUEST;
  postalCode?: string;
  city?: string;
  countryCode?: CountryCode;
}

export interface MainCartRetrievalFailedAction {
  type: typeof MAIN_CART_RETRIEVAL_FAILED;
  error: CartError;
}
export const mainCartRetrieval = (
  postalCode?: string,
  city?: string,
  countryCode?: CountryCode,
): MainCartRetrievalRequestAction => ({
  type: MAIN_CART_RETRIEVAL_REQUEST,
  countryCode,
  city,
  postalCode,
});

export const mainCartRetrievalFailed = (
  error: CartError,
): MainCartRetrievalFailedAction => ({
  type: MAIN_CART_RETRIEVAL_FAILED,
  error,
});

// Will replace both b2b and b2c retrieval success
export const MAIN_CART_RETRIEVAL_SUCCESS =
  'shoppingCart/MAIN_CART_RETRIEVAL_SUCCESS';

export interface NewMainCartRetrievalRequestAction {
  type: typeof MAIN_CART_RETRIEVAL_SUCCESS;
  mainCartResponse: MainCartRetrievalResponse;
}

export const mainCartRetrievalSuccess = (
  mainCartResponse: MainCartRetrievalResponse,
): NewMainCartRetrievalRequestAction => ({
  type: MAIN_CART_RETRIEVAL_SUCCESS,
  mainCartResponse,
});

export type MainCartRetrievalActions =
  | MainCartRetrievalRequestAction
  | MainCartRetrievalFailedAction
  | NewMainCartRetrievalRequestAction;
