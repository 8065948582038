export interface CartOverviewItems {
  [key: number]: number;
}

export interface CartOverviewStorage {
  contents: CartOverviewItems;
  id: number;
  productsTotalPrice?: number;
}

export interface CartOverview extends CartOverviewStorage {
  itemsCount: number;
  productCount: number;
}

export type CartOverviewState = {
  fetching: boolean;
  error: string;
} & CartOverview;

export const cartOverview: CartOverview = {
  itemsCount: 0,
  productCount: 0,
  productsTotalPrice: 0,
  contents: {},
  id: 0,
};

export const cartOverviewState: CartOverviewState = {
  itemsCount: 0,
  productCount: 0,
  productsTotalPrice: 0,
  contents: {},
  fetching: false,
  error: '',
  id: 0,
};

export interface CartOverviewProduct {
  id: number;
  quantity: number;
}
