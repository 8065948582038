import type { ProductDetails } from '@/domains/shoppingCart/interfaces/ProductDetails';
import type { FunnelFlow } from '@/domains/shoppingCart/services/msfCheckoutFunnel/interfaces/gateway';

export const MOVE_ITEM_TO_MAIN_REQUEST =
  'shoppingCart/MOVE_ITEM_TO_MAIN_REQUEST';
export const MOVE_ITEMS_TO_MAIN_REQUEST =
  'shoppingCart/MOVE_ITEMS_TO_MAIN_REQUEST';
export const MOVE_ITEM_TO_MAIN_SUCCESS =
  'shoppingCart/MOVE_ITEM_TO_MAIN_SUCCESS';
export const MOVE_ITEM_TO_MAIN_FAILED = 'shoppingCart/MOVE_ITEM_TO_MAIN_FAILED';

export interface MoveItemToMainPayload {
  removedProduct: ProductDetails;
  position: number;
}

export interface MoveItemToMainRequestAction {
  type: typeof MOVE_ITEM_TO_MAIN_REQUEST;
  payload: MoveItemToMainPayload;
}

export interface MoveItemsToMainRequestAction {
  type: typeof MOVE_ITEMS_TO_MAIN_REQUEST;
  payload: { productIds: number[] };
}

export interface MoveItemToMainSuccessPayload {
  product: ProductDetails;
  flow: FunnelFlow;
}

export interface MoveItemToMainSuccessAction {
  type: typeof MOVE_ITEM_TO_MAIN_SUCCESS;
  payload: MoveItemToMainSuccessPayload;
}

export interface MoveItemToMainFailedAction {
  type: typeof MOVE_ITEM_TO_MAIN_FAILED;
  payload: MoveItemToMainPayload;
}

export type MoveItemToMainActions =
  | MoveItemToMainRequestAction
  | MoveItemsToMainRequestAction
  | MoveItemToMainSuccessAction
  | MoveItemToMainFailedAction;

export const moveItemToMain = ({
  position,
  removedProduct,
}: MoveItemToMainPayload): MoveItemToMainRequestAction => ({
  type: MOVE_ITEM_TO_MAIN_REQUEST,
  payload: {
    position,
    removedProduct,
  },
});

export const moveItemsToMain = (
  productIds: number[],
): MoveItemsToMainRequestAction => ({
  type: MOVE_ITEMS_TO_MAIN_REQUEST,
  payload: {
    productIds,
  },
});

export const moveItemToMainSuccess = (
  product: ProductDetails,
  flow: FunnelFlow,
): MoveItemToMainSuccessAction => ({
  type: MOVE_ITEM_TO_MAIN_SUCCESS,
  payload: {
    product,
    flow,
  },
});

export const moveItemToMainFailed = ({
  position,
  removedProduct,
}: MoveItemToMainPayload): MoveItemToMainFailedAction => ({
  type: MOVE_ITEM_TO_MAIN_FAILED,
  payload: {
    position,
    removedProduct,
  },
});
