import type {
  CartOverview,
  CartOverviewStorage,
} from '../interfaces/CartOverview';

export const formatLsForCartOverview = (
  localStorage: CartOverviewStorage | undefined,
): CartOverview => {
  const defaultLocalStorageState = {
    itemsCount: 0,
    contents: {},
    productCount: 0,
    id: 0,
    productsTotalPrice: 0,
  };
  if (!localStorage || !Object.keys(localStorage.contents).length) {
    return defaultLocalStorageState;
  }

  const itemsCount = Object.values(localStorage.contents).reduce(
    (a, b) => a + b,
  );
  const productCount = Object.keys(localStorage.contents).length;
  return {
    itemsCount,
    contents: localStorage.contents,
    id: localStorage.id,
    productCount,
    productsTotalPrice: localStorage.productsTotalPrice,
  };
};
