import {
  cartOverview,
  type CartOverview,
} from '@/domains/shoppingCart/CartOverview/interfaces/CartOverview';
import { sumCartOverviewQuantities } from '@/domains/shoppingCart/common/utils/sumQuantities';

import type { Overview } from '../interfaces/cartOverview';

export const formatForCartOverview = (
  apiCartOverview: Overview | undefined,
): CartOverview => {
  const formattedForCartOverview: CartOverview = cartOverview;
  const overview = apiCartOverview?.overview;
  const cartID = apiCartOverview?.id;
  const productsTotalPrice = apiCartOverview?.productsTotalPrice;

  if (!apiCartOverview || !overview || Object.keys(overview).length === 0) {
    return formattedForCartOverview;
  }
  formattedForCartOverview.contents = overview;

  formattedForCartOverview.productCount = Object.keys(overview).length;

  formattedForCartOverview.itemsCount = sumCartOverviewQuantities(overview);

  formattedForCartOverview.id = Number(cartID) || 0;

  formattedForCartOverview.productsTotalPrice = productsTotalPrice || 0;

  return formattedForCartOverview;
};
