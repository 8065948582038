import type { ProductDetails } from '@/domains/shoppingCart/interfaces/ProductDetails';
import type { MainCartLightContent } from '@/domains/shoppingCart/services/msfCart/interfaces/main';

export const MAIN_CART_ITEM_ADDITION_REQUEST =
  'shoppingCart/MAIN_CART_ITEM_ADDITION_REQUEST';

export const MAIN_CART_ITEM_ADDITION_SUCCESS =
  'shoppingCart/MAIN_CART_ITEM_ADDITION_SUCCESS';

export const MAIN_CART_ITEM_ADDITION_FAILED =
  'shoppingCart/MAIN_CART_ITEM_ADDITION_FAILED';

// should be string when fully msf
export interface MainCartItemAdditionPayload {
  productId: number;
  removedProduct: ProductDetails;
  position: number;
}

export interface MainCartItemAdditionFailedPayload {
  productId: number;
  position: number;
}

export interface MainCartItemAdditionRequestAction {
  type: typeof MAIN_CART_ITEM_ADDITION_REQUEST;
  payload: MainCartItemAdditionPayload;
}

export const mainCartItemAdditionRequest = ({
  position,
  productId,
  removedProduct,
}: MainCartItemAdditionPayload): MainCartItemAdditionRequestAction => ({
  type: MAIN_CART_ITEM_ADDITION_REQUEST,
  payload: { position, productId, removedProduct },
});

export interface MainCartItemAdditionSuccessAction {
  type: typeof MAIN_CART_ITEM_ADDITION_SUCCESS;
  payload: {
    mainCartLight: MainCartLightContent;
  };
}

export const mainCartItemAdditionSuccess = (
  payload: MainCartLightContent,
): MainCartItemAdditionSuccessAction => ({
  type: MAIN_CART_ITEM_ADDITION_SUCCESS,
  payload: { mainCartLight: { ...payload } },
});

export interface MainCartItemAdditionFailedAction {
  type: typeof MAIN_CART_ITEM_ADDITION_FAILED;
  payload: MainCartItemAdditionFailedPayload;
}

export const mainCartItemAdditionFailed = ({
  position,
  productId,
}: MainCartItemAdditionFailedPayload): MainCartItemAdditionFailedAction => ({
  type: MAIN_CART_ITEM_ADDITION_FAILED,
  payload: { position, productId },
});

export type MainCartItemAdditionActions =
  | MainCartItemAdditionRequestAction
  | MainCartItemAdditionSuccessAction
  | MainCartItemAdditionFailedAction;
