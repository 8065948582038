import { SafeStorage } from '@/domains/core/data/utils/SafeStorage/SafeStorage';
import { logShoppingCartError } from '@/domains/shoppingCart/common/utils/logShoppingCartError';

import { LOCAL_STORAGE_KEY } from '../CartOverview.constants';
import type { CartOverviewItems } from '../interfaces/CartOverview';

interface WriteToLocalStorage {
  cartOverviewContents: CartOverviewItems | undefined;
  id: number;
  owner: string;
  ttl: number;
  productsTotalPrice: number;
}

export const writeCartOverviewToLocalStorage = ({
  cartOverviewContents,
  id,
  owner,
  ttl,
  productsTotalPrice,
}: WriteToLocalStorage) => {
  try {
    const safeLocalStorage = SafeStorage.getLocalStorage();

    const options = { ttl, owner };
    safeLocalStorage.setItem(
      LOCAL_STORAGE_KEY,
      { contents: cartOverviewContents, id, productsTotalPrice },
      options,
    );
  } catch (error) {
    logShoppingCartError(
      new Error(`fail to write in local storage`),
      'cartOverview',
    );
  }
};
