import { httpClient } from '@/domains/core/httpClient/httpClient';
import { HttpHeaders } from '@/domains/core/httpClient/httpHeaders';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import { getPlatform } from '@/domains/core/settings/utils';

import {
  cartOverviewState,
  type CartOverview,
} from '../../CartOverview/interfaces/CartOverview';
import type { CartOverviewContent as MsfCartOverviewContent } from './interfaces/cartOverview';
import { formatForCartOverview } from './utils/formatForCartOverview';

export const GET_MSF_CART_OVERVIEW_URL = `/web/api/v1/cart-module/overview`;

export const getCartOverview = async (): Promise<CartOverview> => {
  const response = await httpClient.get<{ content: MsfCartOverviewContent }>(
    `${getAppSetting('MS_API_URL')}${GET_MSF_CART_OVERVIEW_URL}`,
    {
      headers: {
        [HttpHeaders.X_MM_PLATFORM]: getPlatform().toUpperCase(),
      },
    },
  );

  return (
    formatForCartOverview(response.data?.content?.cart?.main) ||
    cartOverviewState
  );
};
