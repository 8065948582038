import logger from '@/domains/core/observability/logger';

export const logShoppingCartError = (
  error: Error = new Error('!undefined error!'),
  page:
    | 'addToCart'
    | 'cancelRemoveProductFromCart'
    | 'cancelRemoveProductFromSavedCart'
    | 'cartCount'
    | 'changeProductQuantity'
    | 'changeSavedProductQuantity'
    | 'getCurrentCart'
    | 'getCurrentSavedCart'
    | 'shareKeyRetrieval'
    | 'removeProductFromCart'
    | 'removeProductFromSavedCart'
    | 'savedCartCount'
    | 'saveFurtherInformation'
    | 'saveProduct'
    | 'unsaveProduct'
    | 'unsaveAllProducts'
    | 'cartOverview'
    | 'getFreeDeliveryFees'
    | 'asideCartRetrieval'
    | 'asideCartItemEdition'
    | 'asideCartItemAddition'
    | 'asideCartItemDeletion'
    | 'moveItemToMain'
    | 'mainCartRetrieval'
    | 'mainCartItemEdition'
    | 'mainCartItemAddition'
    | 'mainCartItemDeletion'
    | 'moveItemToAside'
    | 'mainCartToPDF',
  scopeTag = 'shoppingCart',
) => {
  logger.error(
    new Error(
      'Page: ' + page + ', scopeTag: ' + scopeTag + ', error: ' + error.message,
    ),
  );
};
