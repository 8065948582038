export type SafeStorageGetItemConverter = (v: any) => any;
export interface SafeStorageGetItemOptions {
  converter?: SafeStorageGetItemConverter;
  owner?: string;
}

export type SafeStorageSetItemConverter = (v: any) => string;
export interface SafeStorageSetItemOptions {
  ttl?: number;
  converter?: SafeStorageSetItemConverter;
  owner?: string;
}

export interface SafeStorageItem {
  value: string;
  expiry?: number;
  owner?: string;
}

let localStorageSingleton: SafeStorage | null = null;
let sessionStorageSingleton: SafeStorage | null = null;

export class SafeStorage {
  storage: Storage | null;

  static getLocalStorage(): SafeStorage {
    if (!localStorageSingleton) {
      let storage;
      try {
        storage = window.localStorage;
      } catch (e) {
        storage = null;
      }

      localStorageSingleton = Object.freeze(new SafeStorage(storage));
    }
    return localStorageSingleton;
  }

  static getSessionStorage(): SafeStorage {
    if (!sessionStorageSingleton) {
      let storage;
      try {
        storage = window.sessionStorage;
      } catch (e) {
        storage = null;
      }

      sessionStorageSingleton = Object.freeze(new SafeStorage(storage));
    }
    return sessionStorageSingleton;
  }

  constructor(storage: Storage | null) {
    if (storage) {
      const key = '__safe_storage__';
      try {
        storage.setItem(key, 'test');
        storage.removeItem(key);
        this.storage = storage;
      } catch (e) {
        this.storage = null;
      }
    } else {
      this.storage = null;
    }
  }

  get length(): number {
    if (!this.storage) {
      return 0;
    }

    return this.storage.length;
  }

  get isStorageAvailable(): boolean {
    return !!this.storage;
  }

  key(index: number): string | null {
    if (!this.storage) {
      return null;
    }

    return this.storage.key(index);
  }

  getItem(key: string, options?: SafeStorageGetItemOptions): any {
    if (!this.storage) {
      return null;
    }

    const { converter, owner: ownerFromOptions } = options || {};
    const rawItem = this.storage.getItem(key);
    if (!rawItem) {
      return null;
    }

    let item: SafeStorageItem;
    try {
      item = JSON.parse(rawItem);
      if (typeof item !== 'object') {
        // Never trust what is coming from storage
        throw new Error('Untrusted item!');
      }
    } catch (e) {
      // Probably corrupted or untrusted item value
      this.removeItem(key);
      return null;
    }

    const { expiry, value, owner } = item;
    if (
      value === undefined ||
      (expiry &&
        (typeof expiry !== 'number' ||
          Number.isNaN(expiry) ||
          new Date().getTime() > expiry)) ||
      (ownerFromOptions && ownerFromOptions !== owner)
    ) {
      this.removeItem(key);
      return null;
    }

    return converter ? converter(value) : value;
  }

  setItem(
    key: string,
    value: any,
    options?: SafeStorageSetItemOptions,
  ): SafeStorage {
    if (this.storage) {
      const { ttl, converter, owner: ownerFromOptions } = options || {};
      const item: SafeStorageItem = {
        value: converter ? converter(value) : value,
      };
      if (ttl) {
        item.expiry = new Date().getTime() + ttl;
      }

      if (ownerFromOptions) {
        item.owner = ownerFromOptions;
      }
      this.storage.setItem(key, JSON.stringify(item));
    }

    return this;
  }

  removeItem(key: string): SafeStorage {
    if (this.storage) {
      this.storage.removeItem(key);
    }

    return this;
  }

  clear(): SafeStorage {
    if (this.storage) {
      this.storage.clear();
    }

    return this;
  }
}
