import type { ActionCreator } from 'redux';

import type { ActionWithPayload } from '@/domains/core/applicationState/interfaces';
import type { CartOverview } from '@/domains/shoppingCart/CartOverview/interfaces/CartOverview';

export const GET_CART_OVERVIEW_REQUEST =
  'shoppingCart/CartOverview/GET_CART_OVERVIEW_REQUEST';
export const GET_CART_OVERVIEW_SUCCESS =
  'shoppingCart/CartOverview/GET_CART_OVERVIEW_SUCCESS';
export const GET_CART_OVERVIEW_FAILED =
  'shoppingCart/CartOverview/GET_CART_OVERVIEW_FAILED';
export const GET_CART_OVERVIEW_MANAGER =
  'shoppingCart/CartOverview/GET_CART_OVERVIEW_MANAGER';
export const CLEAN_CART_OVERVIEW_REQUEST =
  'shoppingCart/CartOverview/CLEAN_CART_OVERVIEW_REQUEST';
export const CLEAN_CART_OVERVIEW_SUCCESS =
  'shoppingCart/CartOverview/CLEAN_CART_OVERVIEW_SUCCESS';

export type GetCartOverviewSuccessAction = ActionWithPayload<
  {
    cartOverview: CartOverview;
  },
  typeof GET_CART_OVERVIEW_SUCCESS
>;

export type GetCartOverviewFailedAction = ActionWithPayload<
  {
    error: string;
  },
  typeof GET_CART_OVERVIEW_FAILED
>;

/**
 * Cart overview manager
 */
export type GetCartOverviewManagerAction = ActionWithPayload<
  typeof GET_CART_OVERVIEW_MANAGER
>;

/**
 * Clean local storage
 */
export type CleanCartOverviewRequestAction = ActionWithPayload<
  typeof CLEAN_CART_OVERVIEW_REQUEST
>;

export const getCartOverviewManager: ActionCreator<
  GetCartOverviewManagerAction
> = () => ({
  type: GET_CART_OVERVIEW_MANAGER,
});

export const cleanCartOverview: ActionCreator<
  CleanCartOverviewRequestAction
> = () => ({
  type: CLEAN_CART_OVERVIEW_REQUEST,
});
