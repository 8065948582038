import type { CartError } from '@/domains/shoppingCart/interfaces/CartsState';
import type { AsideCartRetrieval } from '@/domains/shoppingCart/services/msfCart/interfaces/aside';
import type { PreSalesContact } from '@/domains/shoppingCart/services/msfCart/interfaces/common';

export const ASIDE_CART_RETRIEVAL_REQUEST =
  'shoppingCart/ASIDE_CART_RETRIEVAL_REQUEST';
export const ASIDE_CART_RETRIEVAL_SUCCESS =
  'shoppingCart/ASIDE_CART_RETRIEVAL_SUCCESS';
export const ASIDE_CART_RETRIEVAL_FAILED =
  'shoppingCart/ASIDE_CART_RETRIEVAL_FAILED';

export interface AsideCartRetrievalRequestAction {
  type: typeof ASIDE_CART_RETRIEVAL_REQUEST;
}

export interface AsideCartRetrievalSuccessPayload {
  cart: AsideCartRetrieval;
  contact: PreSalesContact;
}
export interface AsideCartRetrievalSuccessAction {
  type: typeof ASIDE_CART_RETRIEVAL_SUCCESS;
  payload: AsideCartRetrievalSuccessPayload;
}

export interface AsideCartRetrievalFailedAction {
  type: typeof ASIDE_CART_RETRIEVAL_FAILED;
  error: CartError;
}

export type AsideCartRetrievalActions =
  | AsideCartRetrievalRequestAction
  | AsideCartRetrievalSuccessAction
  | AsideCartRetrievalFailedAction;

export const asideCartRetrieval = (): AsideCartRetrievalRequestAction => ({
  type: ASIDE_CART_RETRIEVAL_REQUEST,
});
