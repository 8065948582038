import type { countryCode as CountryCode } from '@/domains/customerManagement/interfaces/countryCode';
import { Hit } from '@/domains/productDiscovery/Recommendation/interfaces/algolia';
import type { CrossBorderVat } from '@/domains/shoppingCart/b2b/interfaces/vatCrossBorder';
import type { ProductDetails } from '@/domains/shoppingCart/interfaces/ProductDetails';
import type { Main } from '@/domains/shoppingCart/services/msfCart/interfaces/main';
import type { DeliveryDiscount } from '@/domains/shoppingCart/services/msfCart/mainCartRetrieval/interfaces';

import type { CartDispatch } from './CartDispatch';

export const mainCartInitialState: MainCartState = {
  id: '',
  items: [],
  prices: {
    cartTotalPrice: 0,
    cartTotalPriceExcVat: 0,
    estimatedShippingPrice: 0,
    productsTotalPrice: 0,
    productsTotalPriceExcVat: 0,
    vatCost: 0,
  },
  showCopyShareUrlModal: false,
};

export interface MainCartState extends Omit<Main, 'items'> {
  items: ProductDetails[];
  itemsNotAvailable?: ProcessedItemsNotAvailable;
  shareKey?: string;
  dispatches?: CartDispatch[];
  deliveryDiscount?: DeliveryDiscount;
  showCopyShareUrlModal?: boolean;
  estimatedDeliveryCost?: EstimatedDeliveryCost;
  productsQuantityDiff?: { [key: number]: number }; // Used to handle the optimistic behaviour when we edit a product count
  crossBorderVat?: CrossBorderVat;
  amountSaved?: number;
  cartToPdf?: CartToPdf;

  isExpressCheckoutAvailable?: boolean;
}

export type ItemNotAvailableAlgoliaSearchHit = Pick<
  Hit,
  'title' | 'url' | 'objectID' | 'article_id' | 'model_id'
>;
export interface ProcessedItemsNotAvailable {
  outOfStock?: {
    productDetails: ItemNotAvailableAlgoliaSearchHit[];
    productIds: number[];
    hasError: boolean;
  };
  sellerOptOut?: {
    productDetails: ItemNotAvailableAlgoliaSearchHit[];
    productIds: number[];
    hasError: boolean;
  };
}

interface EstimatedDeliveryCost {
  isModalOpen?: boolean;
  price?: number;
  postalCode?: string;
  city?: string;
  countryCode?: CountryCode;
}
export interface CartToPdf {
  isLoading: boolean;
}
