import type { ProductDetails } from '@/domains/shoppingCart/interfaces/ProductDetails';
import type { MainCartLightContent } from '@/domains/shoppingCart/services/msfCart/interfaces/main';
import type { FunnelFlow } from '@/domains/shoppingCart/services/msfCheckoutFunnel/interfaces/gateway';

export const MOVE_ITEM_TO_ASIDE_REQUEST =
  'shoppingCart/MOVE_ITEM_TO_ASIDE_REQUEST';
export const MOVE_ITEM_TO_ASIDE_SUCCESS =
  'shoppingCart/MOVE_ITEM_TO_ASIDE_SUCCESS';
export const MOVE_ITEM_TO_ASIDE_FAILED =
  'shoppingCart/MOVE_ITEM_TO_ASIDE_FAILED';

export interface MoveItemToAsidePayload {
  productId: number;
  showStatusMessage?: boolean;
  removedProduct: ProductDetails;
  position: number;
}

export interface MoveItemToAsideRequestAction {
  type: typeof MOVE_ITEM_TO_ASIDE_REQUEST;
  payload: MoveItemToAsidePayload;
}

export interface MoveItemToAsideSuccessPayload {
  product: ProductDetails;
  showStatusMessage: boolean;
  flow: FunnelFlow;
  mainCartLight: MainCartLightContent;
}
export interface MoveItemToAsideSuccessAction {
  type: typeof MOVE_ITEM_TO_ASIDE_SUCCESS;
  payload: MoveItemToAsideSuccessPayload;
}

export interface MoveItemToAsideFailedAction {
  type: typeof MOVE_ITEM_TO_ASIDE_FAILED;
  payload: MoveItemToAsidePayload;
}

export type MoveItemToAsideActions =
  | MoveItemToAsideRequestAction
  | MoveItemToAsideSuccessAction
  | MoveItemToAsideFailedAction;

export const moveItemToAside = ({
  productId,
  removedProduct,
  position,
  showStatusMessage = true,
}: MoveItemToAsidePayload): MoveItemToAsideRequestAction => ({
  type: MOVE_ITEM_TO_ASIDE_REQUEST,
  payload: {
    productId,
    showStatusMessage,
    removedProduct,
    position,
  },
});

export const moveItemToAsideSuccess = (
  product: ProductDetails,
  showStatusMessage: boolean,
  flow: FunnelFlow,
  mainCartLight: MainCartLightContent,
): MoveItemToAsideSuccessAction => ({
  type: MOVE_ITEM_TO_ASIDE_SUCCESS,
  payload: {
    product,
    showStatusMessage,
    flow,
    mainCartLight,
  },
});

export const moveItemToAsideFailed = ({
  productId,
  position,
  removedProduct,
  showStatusMessage,
}: MoveItemToAsidePayload): MoveItemToAsideFailedAction => ({
  type: MOVE_ITEM_TO_ASIDE_FAILED,
  payload: {
    productId,
    showStatusMessage,
    position,
    removedProduct,
  },
});
