import type { CartRequestBody } from '@/domains/shoppingCart/services/msfCart/interfaces/common';
import type { MainCartLightContent } from '@/domains/shoppingCart/services/msfCart/interfaces/main';

export const MAIN_CART_ITEM_EDITION_REQUEST =
  'shoppingCart/MAIN_CART_ITEM_EDITION_REQUEST';
export const MAIN_CART_ITEM_EDITION_SUCCESS =
  'shoppingCart/MAIN_CART_ITEM_EDITION_SUCCESS';
export const MAIN_CART_ITEM_EDITION_CALLED =
  'shoppingCart/MAIN_CART_ITEM_EDITION_CALLED';
export const MAIN_CART_ITEM_EDITION_FAILED =
  'shoppingCart/MAIN_CART_ITEM_EDITION_FAILED';

export interface MainCartItemEditionPayload {
  quantity: CartRequestBody;
  productId: number;
}

export interface MainCartItemEditionRequestAction {
  type: typeof MAIN_CART_ITEM_EDITION_REQUEST;
  payload: MainCartItemEditionPayload;
}

export interface ProductIdPayload {
  productId: number;
}

export interface MainCartItemEditionCalledAction {
  type: typeof MAIN_CART_ITEM_EDITION_CALLED;
  payload: ProductIdPayload;
}

export interface MainCartItemEditionSuccessAction {
  type: typeof MAIN_CART_ITEM_EDITION_SUCCESS;
  payload: MainCartItemEditionSuccessPayload;
}

export interface MainCartItemEditionSuccessPayload {
  mainCartLight: MainCartLightContent;
  productId: number;
  diff: number;
}

export interface MainCartItemEditionFailedPayload {
  productId: number;
  diff: number;
}

export interface MainCartItemEditionFailedAction {
  type: typeof MAIN_CART_ITEM_EDITION_FAILED;
  payload: MainCartItemEditionFailedPayload;
}

export type MainCartItemEditionActions =
  | MainCartItemEditionRequestAction
  | MainCartItemEditionSuccessAction
  | MainCartItemEditionFailedAction
  | MainCartItemEditionCalledAction;

export const mainCartItemEdition = (
  quantity: CartRequestBody,
  productId: number,
): MainCartItemEditionRequestAction => ({
  type: MAIN_CART_ITEM_EDITION_REQUEST,
  payload: { quantity, productId },
});

export const mainCartItemEditionCalled = (
  productId: number,
): MainCartItemEditionCalledAction => ({
  type: MAIN_CART_ITEM_EDITION_CALLED,
  payload: { productId },
});

export const mainCartItemEditionSuccess = (
  mainCartLight: MainCartLightContent,
  productId: number,
  diff: number,
): MainCartItemEditionSuccessAction => ({
  type: MAIN_CART_ITEM_EDITION_SUCCESS,
  payload: { mainCartLight, productId, diff },
});

export const mainCartItemEditionFailed = (
  productId: number,
  diff: number,
): MainCartItemEditionFailedAction => ({
  type: MAIN_CART_ITEM_EDITION_FAILED,
  payload: {
    productId,
    diff,
  },
});
